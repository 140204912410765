import React, {Component} from 'react';

import { FacebookProvider, Page } from 'react-facebook';
import TwitterContainer from './TwitterContainer';



export default class Footer extends Component {

     

        
  constructor(props){
    super(props);
    this.state = {
        client : JSON.parse(localStorage.getItem('_profile'))
        }

    }

    

 
  render(){
      
    return (
        <footer className="footer-area" 
        style={{backgroundColor:this.state.client.subdomain_color}}
        >
            <div className="main-footer-area">
                <div className="container">
                    <div className="row">
                        <div className="bottom-footer-area">
                            <div className="container h-100">
                                <div className="row h-100 align-items-center">
                                    <div className="col-12">
                                        <h3>{this.state.client.subdomain_nama}</h3>
                                        <small>{this.state.client.subdomain_alamat}</small>
                                        <p>
                                            2021 Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved 
                                        </p>
                                         
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        )
  }
 
}

